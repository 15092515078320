<template>
  <section id="delayed-unconfirmed-orders">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValue" placeholder="Search" @keyup.enter="searchOrder" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showDelayedOrders" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Delayed orders
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-delayed-orders" />
          </h4>
          <b-popover target="popover-delayed-orders" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="delayedOrdersTable.currentPage"
          :items="delayedOrdersTable.items" :fields="delayedOrdersTable.fields"
          :sort-by.sync="delayedOrdersTable.sortBy" :sort-desc.sync="delayedOrdersTable.sortDesc"
          :sort-direction="delayedOrdersTable.sortDirection" :filter="delayedOrdersTable.filter"
          :filter-included-fields="delayedOrdersTable.filterOn" @sort-changed="sortChangedDelayedOrders" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="delayedOrdersTable.currentPage" :total-rows="delayedOrdersTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleDelayedOrdersTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showUnconfirmedOrders" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Unconfirmed orders
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-unconfirmed-orders" />
          </h4>
          <b-popover target="popover-unconfirmed-orders" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="unconfirmedOrdersTable.currentPage"
          :items="unconfirmedOrdersTable.items" :fields="unconfirmedOrdersTable.fields"
          :sort-by.sync="unconfirmedOrdersTable.sortBy" :sort-desc.sync="unconfirmedOrdersTable.sortDesc"
          :sort-direction="unconfirmedOrdersTable.sortDirection" :filter="unconfirmedOrdersTable.filter"
          :filter-included-fields="unconfirmedOrdersTable.filterOn" @sort-changed="sortChangedUnconfirmedOrders" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="unconfirmedOrdersTable.currentPage" :total-rows="unconfirmedOrdersTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleUnconfirmedOrdersTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BPopover,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    flatPickr,
  },
  data() {
    return {
      showDelayedOrders: true,
      showUnconfirmedOrders: true,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      searchValue: '',
      queryParamsDelayedOrders: {},
      queryParamsUnconfirmedOrders: {},
      delayedOrdersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'order_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'order_no', label: 'order no', sortable: true },
          { key: 'sales_order_no', label: 'sales order no', sortable: true },
          { key: 'supplier_name', label: 'supplier name', sortable: true },
          { key: 'confirmed', label: 'confirmed', sortable: true },
          { key: 'amount_of_positions', label: 'amount of positions', sortable: true },
          {
            key: 'order_amount',
            label: 'order amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'status', label: 'status', sortable: true },
          {
            key: 'order_date',
            label: 'order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'delivery_date',
            label: 'delivery date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      unconfirmedOrdersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'order_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'order_no', label: 'order no', sortable: true },
          { key: 'sales_order_no', label: 'sales order no', sortable: true },
          { key: 'supplier_name', label: 'supplier name', sortable: true },
          { key: 'confirmed', label: 'confirmed', sortable: true },
          { key: 'amount_of_positions', label: 'amount of positions', sortable: true },
          {
            key: 'order_amount',
            label: 'order amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'status', label: 'status', sortable: true },
          {
            key: 'order_date',
            label: 'order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'delivery_date',
            label: 'delivery date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParamsDelayedOrders.from_date = dateRange[0];
    this.queryParamsDelayedOrders.to_date = dateRange[1];
    this.queryParamsUnconfirmedOrders.from_date = dateRange[0];
    this.queryParamsUnconfirmedOrders.to_date = dateRange[1];

    try {
      await this.getDelayedOrders();
      await this.getUnconfirmedOrders();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getDelayedOrders() {
      this.showDelayedOrders = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/delayed-orders/`, this.queryParamsDelayedOrders);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.delayedOrdersTable.items = results;
          this.delayedOrdersTable.totalRows = results[0].count * 2;
        }
        else {
          this.delayedOrdersTable.items = [];
          this.delayedOrdersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showDelayedOrders = false;
      }
    },
    async getUnconfirmedOrders() {
      this.showUnconfirmedOrders = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/unconfirmed-orders/`, this.queryParamsUnconfirmedOrders);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.unconfirmedOrdersTable.items = results;
          this.unconfirmedOrdersTable.totalRows = results[0].count * 2;
        }
        else {
          this.unconfirmedOrdersTable.items = [];
          this.unconfirmedOrdersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showUnconfirmedOrders = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParamsDelayedOrders.from_date = dateRange[0];
        this.queryParamsDelayedOrders.to_date = dateRange[1];
        this.queryParamsUnconfirmedOrders.from_date = dateRange[0];
        this.queryParamsUnconfirmedOrders.to_date = dateRange[1];
        await this.getDelayedOrders();
        await this.getUnconfirmedOrders();
        this.oldDateRange = this.dateRange;
      }
    },
    async searchOrder() {
      this.queryParamsDelayedOrders.page = 1;
      this.queryParamsDelayedOrders.search = '%' + this.searchValue.toLowerCase() + '%';
      this.queryParamsUnconfirmedOrders.page = 1;
      this.queryParamsUnconfirmedOrders.search = '%' + this.searchValue.toLowerCase() + '%';
      await this.getDelayedOrders();
      await this.getUnconfirmedOrders();
    },
    async sortChangedDelayedOrders(value) {
      if (value.sortDesc === true) {
        this.queryParamsDelayedOrders.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsDelayedOrders.ordering = value.sortBy;
      }
      await this.getDelayedOrders();
    },
    async sortChangedUnconfirmedOrders(value) {
      if (value.sortDesc === true) {
        this.queryParamsUnconfirmedOrders.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsUnconfirmedOrders.ordering = value.sortBy;
      }
      await this.getUnconfirmedOrders();
    },
    async handleDelayedOrdersTablePageChange(value) {
      this.queryParamsDelayedOrders.page = value;
      await this.getDelayedOrders();
    },
    async handleUnconfirmedOrdersTablePageChange(value) {
      this.queryParamsUnconfirmedOrders.page = value;
      await this.getUnconfirmedOrders();
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
